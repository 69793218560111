<template>
  <moe-page title="新增供应商">
    <moe-form
      ref="supplierForm"
      :showBack="false"
      :model="supplierParams"
      :rules="rules">
      <el-form-item label="供应商名称" prop="name">
        <el-input v-model.trim="supplierParams.name" placeholder="请输入供应商名称" clearable maxlength="30"></el-input>
      </el-form-item>

      <el-form-item label="供应商图标" prop="icon">
        <moe-upload-file
          v-model="supplierParams.icon"
          ossKey="SHOP_MEDIA"
          :default-file-list="iconList"
          upload-text="上传供应商封面"
          @change="() => $refs['supplierForm'].validateField('icon')" />
      </el-form-item>

      <el-form-item label="供应商介绍" prop="introduce">
        <el-input type="textarea" :rows="5" placeholder="输入供应商介绍" maxlength="100" clearable v-model="supplierParams.introduce"></el-input>
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input :value="supplierParams.sort" @input="(value) => supplierParams.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="输入排序，数值越大排序越靠前" maxlength="10" clearable />
      </el-form-item>

      <template slot="tool">
        <el-button type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button type="primary" icon="el-icon-upload2" :loading="paramsLoad" @click="submit()">提交</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'SupplierManageAdd',
  data() {
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
      icon: verify.isEmpty('请上传活动封面'),
    };
    return {
      supplierParams: {
        id: '',
        name: '',
        icon: '',
        introduce: '',
        sort: '',
        status: false,
      },
      iconList: [],
      rules,
      paramsLoad: false,
    }
  },
  methods: {
    /** 提交 */
    submit() {
      this.$refs['supplierForm'].validate(() => {
        this.paramsLoad = true;
        if (this.supplierParams.id) {
          this.req_updateVendor();
        } else {
          this.req_addVendor();
        }
      })
    },
    /** 创建供应商 */
    req_addVendor() {
      this.$moe_api.SUPPLIER_API.addVendor({
        ...this.supplierParams,
        sort: this.supplierParams.sort || 0
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增供应商成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.paramsLoad = false;
      })
    },
    /** 修改供应商 */
    req_updateVendor() {
      this.$moe_api.SUPPLIER_API.updateVendor({
        ...this.supplierParams,
        sort: this.supplierParams.sort || 0
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑供应商成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.paramsLoad = false;
      })
    },
    /** 查询供应商详情 */
    req_getVendorDetail() {
      if (this.$route.query.id) {
        this.$moe_api.SUPPLIER_API.getVendorDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            const { id, name, icon, introduce, sort, status } = data.result;
            this.supplierParams = {
              id,
              name,
              icon: this.$moe_yunHelper.formatterImageUrl(icon),
              introduce,
              sort,
              status
            }

            this.iconList = [{
              url: icon,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(icon)
            }];
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.paramsLoad = false;
        })
      }
    }
  },
  mounted() {
    this.req_getVendorDetail();
  },
}
</script>

<style lang="scss">

</style>